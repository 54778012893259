<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Thème</v-card-title>
        <v-card-subtitle>Modifiez l'apparence de l'application, sélectionnez l'un des thèmes disponibles.</v-card-subtitle>
        <v-card-text>
            <v-switch class="ml-4" v-model="selectedTheme" :label="this.$vuetify.theme.dark ? 'Sombre' : 'Clair'" @change="() => this.$vuetify.theme.dark = !this.$vuetify.theme.dark"></v-switch>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="selectedTheme === theme ? true : false" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="selectedTheme === theme ? true : false" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Theme',
    data() {
        return {
            selectedTheme : JSON.parse(localStorage.getItem('settings')).preferencies.theme,
            theme : JSON.parse(localStorage.getItem('settings')).preferencies.theme,
        }
    },
    methods : {
        saveSettings() {
            if (this.selectedTheme !== this.theme) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.preferencies.theme = this.selectedTheme;
                this.theme = this.selectedTheme;
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            this.selectedTheme = this.theme;
        }
    },
}
</script>